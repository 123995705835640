// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  color: #191919;
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 2rem;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 4.8rem;
`;

export const ErrorIcon = styled(Icons.Error404).attrs({
  fill: '#b2b2b2',
})`
  margin-bottom: 3.2rem;
`;
