// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import Link from '../../other/link';
import { StyledButton } from '../global.styles';

import { Wrapper, Title, Description, ErrorIcon } from './styles';

// =============================
// Page
// =============================

const PageNotFound = ({ t }) => (
  <Wrapper>
    <Title>{t('pages:error.title')}</Title>
    <Description>{t('pages:error.desc')}</Description>
    <ErrorIcon />
    <Link href="/">
      <StyledButton>{t('pages:error.back_to_home')}</StyledButton>
    </Link>
  </Wrapper>
);

PageNotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default PageNotFound;
