// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import Head from 'next/head';

import { withTranslation } from '../config/i18n';

import PageNotFound from '../components/layouts/notFound';
import { WrapperPageNotFound } from '../components/layouts/global.styles';

import { getWebsiteTitle } from '../helpers/misc';

// =============================
// Page
// =============================

const NotFound = ({ t }) => (
  <WrapperPageNotFound>
    <Head>
      <title>{getWebsiteTitle(t('pages:error.meta_title'))}</title>
    </Head>
    <PageNotFound t={t} />
  </WrapperPageNotFound>
);

NotFound.getInitialProps = async () => ({
  namespacesRequired: ['common', 'pages', 'components', 'errors'],
});

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(NotFound);
